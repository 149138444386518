(function () {

    if (!!document.querySelector('.bank-transfer')) {
        let bank_transfer = document.querySelectorAll('.bank-transfer');

        bank_transfer.forEach(elem => {
            let toggler = elem.querySelector('.bank-transfer__toggler');

            if( toggler ){
                toggler.onclick = () => {

                    elem.classList.toggle('isActive');
                }
            }
        });

        let bank_print_button = document.querySelectorAll('.bank-transfer__button > span');

        if( bank_print_button.length > 0 ){
            ([...bank_print_button]).forEach(item => {
                item.onclick = () => {
                    window.print();
                }
            })
        }
    }

})();