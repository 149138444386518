import Swal from 'sweetalert2'; 
import {
    uscorp_update_recaptcha
} from './uscorp_spam_check';


(function () {

    if (!!document.querySelector('.js-uscorp_chimp_form')) {
        let forms = document.querySelectorAll('.js-uscorp_chimp_form');

        forms.forEach(form => {
            uscorp_chimp_form(form);
        });
    }

    function uscorp_chimp_form(elem) {

        let fields = elem.querySelectorAll('.uscorp_chimp_form__field');
        let submit_field = elem.querySelector('.uscorp_chimp_form__submit');
        // let mailchimp_list_id = elem.dataset.mailchimpList;
        let target_url = data.mailchimpAddSubscriberRoute;

        let captcha_response = elem.querySelector(`input[name="uscorp-g-recaptcha"]`);

        let isPdfDownloadForm = elem.classList.contains('company-download-pdf__form');

        function setFocusHandlers() {
            let text_inputs = elem.querySelectorAll('input[type="text"], input[type="email"], input[type="tel"], textarea');

            ([...text_inputs]).forEach(input => {
                let input_wrapper = input.closest('.uscorp_chimp_form__field');
                let field_type = input_wrapper.dataset.fieldType;
                input.onfocus = () => {
                    input_wrapper.classList.add('isFocused');
                };
                input.onblur = () => {
                    input_wrapper.classList.remove('isFocused');
                    validateInput(field_type, input.value, input_wrapper);

                    checkErrors();
                }

                //quickfix
                if (validateInput(field_type, input.value)) {
                    input_wrapper.classList.add('is-valid');
                }
            });
        }

        function validateSelect(){
            let selects = elem.querySelectorAll('select');
            
            if( selects.length > 0 ){
                ([...selects]).forEach(select=> {
                    if( !select.value || select.value == '' ){
                        let parent_div = ( select.closest('.uscorp_chimp_form__field') ) ? select.closest('.uscorp_chimp_form__field') : select.parentNode;
                        
                        parent_div.classList.add('has-error');

                        select.onchange = () => {
                            parent_div.classList.remove('has-error');
                        }
                    }
                })
            }
        }

        function validateInput(type, value, wrapper = null) {
            let isValid = false;
            if (type == 'text' || type == 'textarea') {
                isValid = (value.length > 2);
            } else if (type == 'email') {
                if (wrapper) {
                    let input = wrapper.querySelector('input');
                    isValid = (typeof input.checkValidity === 'function') ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
                } else {
                    isValid = (/\S+@\S+\.\S+/.test(value));
                }
            }else if( type == 'tel' ){
                if (wrapper) {
                    let input = wrapper.querySelector('input');
                    isValid = (typeof input.checkValidity === 'function') ? input.checkValidity() : (value.length > 2);
                } else {
                    isValid = (value.length > 2);
                }
            } else if( type == 'uscorp_select' || type == 'select' ){
                isValid = ( value && value != '' );
            } else {
                console.log('invalid field type!');

                isValid = false;
            }

            //todo error message;
            if (!wrapper) {
                return isValid;
            }

            if (isValid) {
                wrapper.classList.remove('has-error');
                wrapper.classList.add('is-valid');
            } else {
                wrapper.classList.add('has-error');
                wrapper.classList.remove('is-valid');
            }

            return isValid;
        }

        function checkErrors() {
            validateSelect();

            var errors = elem.querySelectorAll('.has-error');
            var submit_btn = submit_field.querySelector('input[type="submit"]');
            if (errors.length > 0) {
                submit_btn.setAttribute('disabled', true);
            } else {
                submit_btn.removeAttribute('disabled');
            }

            return ( errors.length > 0 ) ;
        }

        function setSendButtonHandler() {
            
            let form = elem.querySelector('form');

            form.onsubmit = (e) => {
                e.preventDefault();
                if( checkErrors() ) {
                    return false;
                };
                if( captcha_response ){
                    uscorp_update_recaptcha( captcha_response, () => {
                        submit_field.classList.add('isLoading');
                        sendForm(form)
                            .then((data) => {
                                let status_code = data.status_code;
                                submit_field.classList.remove('isLoading');
                                if (status_code == 200) {
                                    if( isPdfDownloadForm ){
                                        let wrapper = elem.closest('.company-download-pdf');
                                        if( wrapper ){
                                            wrapper.classList.add('hasSuccess');
                                        }
                                    }else{
                                        makeSuccessBox();
                                    }
                                    clearForm();
                                }else{
                                    makeErrorBox();
                                }
                            });
                    } );
                }else{
                    submit_field.classList.add('isLoading');
                    sendForm(form)
                        .then((data) => {
                            let status_code = data.status_code;
                            submit_field.classList.remove('isLoading');
                            if (status_code == 200) {
                                if( isPdfDownloadForm ){
                                    let wrapper = elem.closest('.company-download-pdf');
                                    if( wrapper ){
                                        wrapper.classList.add('hasSuccess');
                                    }
                                }else{
                                    makeSuccessBox();
                                }
                                clearForm();
                            }else{
                                makeErrorBox();
                            }
                        });
                }
                
            }
        }

        function sendForm(form) {
            let data = new FormData(form);


            let firstRun = true;
            for (let key of data.keys()) {
                if (firstRun) {
                    target_url += '?';
                    firstRun = false;
                } else target_url += '&';

                target_url += key + "=" + data.get(key);
            }


            return fetch(target_url, {
                method: 'GET',
                // body: myBody, // string or object
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        }

        function makeSuccessBox() {
            Swal.fire({
                icon: 'success',
                title: data.i18n.book_popup_success_title,
                text: data.i18n.book_popup_success_text,
                showConfirmButton: false,
                showCloseButton: true
            });
        }

        function makeErrorBox() {
            Swal.fire({
                icon: 'error',
                title: data.i18n.book_popup_failed_title,
                text: data.i18n.book_popup_failed_text,
                showConfirmButton: false,
                showCloseButton: true,
            });
        }
        
        function clearForm(){
            ([...fields]).forEach(field => {
                var input = field.querySelector('input');
                if( input && input.type != 'submit' ){
                    input.value = '';
                }
            })
        }

        setFocusHandlers();
        setSendButtonHandler();
    }

})();