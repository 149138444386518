(function () {

    if (!!document.querySelector('.uscorp_companies_list__item')) {
        let items = document.querySelectorAll('.uscorp_companies_list__item');

        items.forEach(item => {
            let benefits_btn = item.querySelector('.uscorp_companies_list__item-benefits_btn');
            
            if( benefits_btn ){
                benefits_btn.onclick = () => {
                    item.classList.toggle('isToggled');
                }
            }
        });
    }    

})();