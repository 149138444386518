import Swal from 'sweetalert2';


(function () {

    if (!!document.querySelector('.uscorp_wpcf_chimp')) {
        let forms = document.querySelectorAll('.uscorp_wpcf_chimp');

        forms.forEach(form => {
            uscorp_wpcf_chimp_form(form);
        });
    }

    function uscorp_wpcf_chimp_form(elem) {

        let list_id = elem.dataset.mailchimpList;
        let target_url = data.mailchimpAddSubscriberRoute;
        let wpcf = elem.querySelector('.wpcf7');

        function setWPCFsubmitListeners() {
            //todo
        }

        function setWPCFMailSentListeners() {
            if (wpcf) {
                wpcf.addEventListener('wpcf7mailsent', function (event) {
        
                    let data = [];
                    let inputs = event.detail.inputs;

                    for (var i = 0; i < inputs.length; i++) {
                        let obj = {};
                        obj.name = inputs[i].name;
                        obj.value = inputs[i].value;

                        data.push(obj);
                    }

                    data.push({
                        name: 'list_id',
                        value: list_id
                    });

                    data = data.filter( item => item.name != 'g-recaptcha-response' );
                    console.log('data', data);
                    sendFormToMailchimp( data )
                        .then((data) => {
                            let status_code = data.status_code;
                            console.log('data', data);
                        });

                }, false);
            }
        }

        function sendFormToMailchimp(data) {

            let firstRun = true;
            ([...data]).forEach(data_obj=>{
                if (firstRun) {
                    target_url += '?';
                    firstRun = false;
                } else target_url += '&';
                    
                target_url += data_obj.name + "=" + data_obj.value;
            })

            return fetch(target_url, {
                method: 'GET',
                // body: myBody, // string or object
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        
        }


        setWPCFsubmitListeners();
        setWPCFMailSentListeners();
    }

})();