function uscorp_card_element(elem) {

    let card_holder = elem.querySelector('.uscorp_card_element-holder');
    let card_errors = elem.querySelector('.uscorp_card_element-errors');

    let stripe = Stripe(data.stripe_public_key);
    let elements = stripe.elements();
    let card = null;

    function createCard() {
        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
            base: {
                color: '#12336B',
                fontFamily: 'Roboto, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: 'rgba(0, 0, 0, 0.4)'
                }
            },
            invalid: {
                color: '#FF0101',
                iconColor: '#FF0101'
            }
        };

        // Create an instance of the card Element.
        card = elements.create('card', {
            style: style
        });

        card.mount(card_holder);
    }

    function handleCardChange() {
        card.on('change', function (event) {
            
            if (event.error) {
                card_errors.textContent = event.error.message;
            } else {
                card_errors.textContent = '';
            }
        });
    }

    createCard();
    handleCardChange();

    return { 'card' : card, 'stripe' : stripe };
}

export { uscorp_card_element }