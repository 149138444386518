(function () {

    if (!!document.querySelector('.uscorp_chimp_form__select')) {
        let selects = document.querySelectorAll('.uscorp_chimp_form__select');

        selects.forEach(select => {
            uscorp_custom_select(select);
        });
    }

    function uscorp_custom_select(elem) {

        let select = elem.querySelector('select');
        let sel_options = select.options;
        let sel_handler = null;
        let sel_value = null;

        function hideSelect() {
            let select = elem.querySelector('select');

            select.style.opacity = 0;
            select.style.display = 'none';
        }

        function createSelectHandler() {
            let select_handler = document.createElement('div');

            select_handler.classList.add('uscorp_custom_select__handler');
            sel_handler = select_handler;

            let select_value = document.createElement('div');
            select_value.classList.add('uscorp_custom_select__value');
            sel_value = select_value;

            let select_options = document.createElement('div');
            select_options.classList.add('uscorp_custom_select__options');

            ([...sel_options]).forEach(item => {
                let opt_dom = document.createElement('div');
                opt_dom.classList.add('uscorp_custom_select__option');
                opt_dom.innerText = item.text;
                opt_dom.dataset.opt_value = item.value;

                if (item.disabled) {
                    opt_dom.classList.add('isDisabled');
                    opt_dom.onclick = (e) => {
                        e.preventDefault();
                    }
                }else{
                    opt_dom.onclick = () => {
                        setValue(item.value, item.text);
                    }
                }

                if (item.selected) {
                    select_value.innerHTML = `<span> ${item.text} </span>`;
                    if( item.value != '' ){
                        select_handler.classList.add('isValidated');
                    }
                }

                select_options.appendChild(opt_dom);
            });


            select_value.onclick = () => {
                select_handler.classList.add('isFocused');

                setTimeout(()=> {
                    hideOnClickOutside(select_options);
                }, 10);
            }

            select_handler.appendChild(select_value);
            select_handler.appendChild(select_options);

            elem.appendChild(select_handler);

        }

        function setValue( val, text ){
            sel_value.innerHTML = `<span> ${text} </span>`;
            select.value = val;

            sel_handler.classList.remove('isFocused');
            sel_handler.classList.add('isValidated');

            if ("createEvent" in document) {
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                select.dispatchEvent(evt);
            } else{
                select.fireEvent("onchange");
            }
                
        }

        function hideOnClickOutside(element) {
            const outsideClickListener = event => {
                if (!element.contains(event.target) &&  event.target.closest('.uscorp_custom_select__options')) { // or use: event.target.closest(selector) === null
                    sel_handler.classList.remove('isFocused');
                    removeClickListener()
                }
            }

            const removeClickListener = () => {
                document.removeEventListener('click', outsideClickListener)
            }

            document.addEventListener('click', outsideClickListener)
        }

        const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

        hideSelect();
        createSelectHandler();
    }



})();