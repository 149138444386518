import {
    uscorp_card_element
} from './uscorp_card_field';
import {
    uscorp_update_recaptcha
} from './uscorp_spam_check';

import Swal from 'sweetalert2';

(function () {

    if (!!document.querySelector('.js-reservation-form')) {
        let forms = document.querySelectorAll('.js-reservation-form');

        forms.forEach(form => {
            uscorp_reservation_form(form);
        });
    }

    function uscorp_reservation_form(elem) {

        let reservation_request_url = data.companyReservationRequestUrl;

        let formEl = elem.querySelector('form');
        let fields = elem.querySelectorAll('.uscorp_chimp_form__field');
        let submit_field = elem.querySelector('.uscorp_chimp_form__submit');

        let stripeElement = null;
        let cardWrapper = null;
        let stripeTokkenEl = null;

        let captcha_response = elem.querySelector(`input[name="uscorp-g-recaptcha"]`);

        let isBankTransfer = false;


        let target_url = null;


        function initCard() {

            if (!!document.querySelector('.js-uscorp_card_element')) {
                let element = document.querySelector('.js-uscorp_card_element');

                stripeElement = uscorp_card_element(element);
                cardWrapper = element;

            }

        }

        function setFocusHandlers() {
            let text_inputs = elem.querySelectorAll('input[type="text"], input[type="email"], input[type="tel"], textarea');

            ([...text_inputs]).forEach(input => {
                let input_wrapper = input.closest('.uscorp_chimp_form__field');
                let field_type = input_wrapper.dataset.fieldType;
                input.onfocus = () => {
                    input_wrapper.classList.add('isFocused');
                };
                input.onblur = () => {
                    input_wrapper.classList.remove('isFocused');
                    validateInput(field_type, input.value, input_wrapper);

                    checkErrors();
                }

                //quickfix
                if (validateInput(field_type, input.value)) {
                    input_wrapper.classList.add('is-valid');
                }
            });
        }

        function validateSelect() {
            let selects = elem.querySelectorAll('select');

            if (selects.length > 0) {
                ([...selects]).forEach(select => {
                    if (!select.value || select.value == '') {
                        let parent_div = (select.closest('.uscorp_chimp_form__field')) ? select.closest('.uscorp_chimp_form__field') : select.parentNode;

                        parent_div.classList.add('has-error');

                        select.onchange = () => {
                            parent_div.classList.remove('has-error');
                        }
                    }
                })
            }
        }

        function validateInput(type, value, wrapper = null) {
            let isValid = false;
            if (type == 'text' || type == 'textarea') {
                isValid = (value.length > 2);
            } else if (type == 'email') {
                if (wrapper) {
                    let input = wrapper.querySelector('input');
                    isValid = (typeof input.checkValidity === 'function') ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
                } else {
                    isValid = (/\S+@\S+\.\S+/.test(value));
                }
            } else if (type == 'tel') {
                if (wrapper) {
                    let input = wrapper.querySelector('input');
                    isValid = (typeof input.checkValidity === 'function') ? input.checkValidity() : (value.length > 2);
                } else {
                    isValid = (value.length > 2);
                }
            } else if (type == 'uscorp_select' || type == 'select') {
                isValid = (value && value != '');
            } else {
                console.log('invalid field type!');

                isValid = false;
            }

            //todo error message;
            if (!wrapper) {
                return isValid;
            }

            if (isValid) {
                wrapper.classList.remove('has-error');
                wrapper.classList.add('is-valid');
            } else {
                wrapper.classList.add('has-error');
                wrapper.classList.remove('is-valid');
            }

            return isValid;
        }

        function checkErrors() {
            validateSelect();

            var errors = elem.querySelectorAll('.has-error');
            var submit_btn = submit_field.querySelector('input[type="submit"]');
            if (errors.length > 0) {
                submit_btn.setAttribute('disabled', true);
            } else {
                submit_btn.removeAttribute('disabled');
            }

            return (errors.length > 0);
        }

        function setSendButtonHandler() {
            let form = elem.querySelector('form');

            form.onsubmit = (e) => {
                e.preventDefault();
                if (captcha_response) {
                    uscorp_update_recaptcha(captcha_response, () => {
                        var event = new Event('uscorpReservationFormSubmit');
                        form.dispatchEvent(event);
                    });
                }

            }
        }

        function setFormSubmitHandler() {
            let form = elem.querySelector('form');
            form.addEventListener('uscorpReservationFormSubmit', () => {

                if (checkErrors()) {
                    return false;
                };

                let payment_cb = null;
                if (isBankTransfer) {
                    payment_cb = checkBankTransfer;
                } else {
                    payment_cb = createPaymentMethod;
                }

                payment_cb().then((hasErrors) => {
                    if (hasErrors == false && hasErrors != undefined) {
                        submit_field.classList.add('isLoading');

                        sendForm(form)
                            .then((reservation_data) => {

                                if (reservation_data.hasOwnProperty("error") && reservation_data.error == false) {
                                    Swal.fire({
                                        icon: 'success',
                                        text: reservation_data.message,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    });
                                    removeForm();
                                } else {
                                    if (reservation_data.hasOwnProperty("message")) {
                                        Swal.fire({
                                            icon: 'error',
                                            text: reservation_data.message,
                                            showConfirmButton: false,
                                            showCloseButton: true,
                                        });
                                    } else {
                                        Swal.fire({
                                            icon: 'error',
                                            text: data.i18n.reservation_failed_text,
                                            showConfirmButton: false,
                                            showCloseButton: true,
                                        });
                                    }
                                }
                                console.log('reservation_data', reservation_data);
                                submit_field.classList.remove('isLoading');
                            })
                            .catch((err) => {
                                console.log('err', err);
                                submit_field.classList.remove('isLoading');
                                Swal.fire({
                                    icon: 'error',
                                    title: data.i18n.reservation_failed_title,
                                    text: data.i18n.reservation_failed_text,
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                });
                            });
                    }
                })

                return false;

            }, false)

        }


        function sendForm() {
            let data = new FormData(formEl);

            let endpoint_url = reservation_request_url;
            if (isBankTransfer) {
                endpoint_url += 'bank_transfer/'
            }

            return fetch(endpoint_url, {
                method: 'POST',
                body: data,
                headers: {
                    // 'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        }

        //return (bool) has_errors
        function checkBankTransfer() {
            return new Promise((resolve, reject) => {
                let bankTransferVal = document.createElement("input");

                bankTransferVal.setAttribute("type", "hidden");
                bankTransferVal.setAttribute("name", "isBankTransfer");
                bankTransferVal.setAttribute("value", "1");

                formEl.appendChild(bankTransferVal);

                resolve(false);
            });
        }

        //return (bool) has_errors
        function createPaymentMethod() {
            let bankTransferVal = formEl.querySelector('input[name="isBankTransfer"]');
            if (bankTransferVal) {
                bankTransferVal.remove();
            }


            return stripeElement.stripe.createPaymentMethod({
                type: 'card',
                card: stripeElement.card,
            }).then(function (result) {
                if (result.error) {
                    if (cardWrapper) {
                        let errorElement = cardWrapper.querySelector('.uscorp_card_element-errors');
                        if (errorElement) {
                            errorElement.textContent = result.error.message;
                        }
                    }
                    return true; //(bool) has_errors
                } else {

                    let form_data = new FormData(formEl);
                    form_data.append('payment_method_id', result.paymentMethod.id);

                    submit_field.classList.add('isLoading');

                    let endpoint_url = reservation_request_url;
                    endpoint_url += 'create_payment_intent/';

                    return fetch(endpoint_url, {
                            method: 'POST',
                            body: form_data,
                            headers: {
                                // 'Content-Type': 'application/json'
                            }
                        })
                        .then(response => response.json())
                        .then(data => {
                            
                            return handleServerResponse(data);
                        });

                }
            });
        }

        //return (bool) has_errors
        function handleServerResponse(response) {
            if (response.error) {
                submit_field.classList.remove('isLoading');
                return true;
            } else if (response.requires_action) {
                submit_field.classList.remove('isLoading');
                // Use Stripe.js to handle required card action
                return handleStripeAction(response);
            } else {
                if (response.reservation_id) {
                    let reservationIdVal = document.createElement("input");

                    reservationIdVal.setAttribute("type", "hidden");
                    reservationIdVal.setAttribute("name", "reservation_id");
                    reservationIdVal.setAttribute("value", response.reservation_id);

                    formEl.appendChild(reservationIdVal);
                }
                return false;
                // Show success message
            }
        }

        function handleStripeAction(response) {

            let endpoint_url = reservation_request_url;
            endpoint_url += 'confirm_payment_intent/';

            return stripeElement.stripe.handleCardAction(
                response.payment_intent_client_secret
            ).then(function (result) {
                if (result.error) {
                    if (cardWrapper) {
                        let errorElement = cardWrapper.querySelector('.uscorp_card_element-errors');
                        if (errorElement) {
                            errorElement.textContent = result.error.message;
                        }
                    }
                } else {
                    // The card action has been handled
                    // The PaymentIntent can be confirmed again on the server

                    return fetch(endpoint_url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            payment_intent_id: result.paymentIntent.id
                        })
                    }).then(function (confirmResult) {
                        return confirmResult.json();
                    }).then((resp) => {
                        return handleServerResponse(resp)
                    });
                }
            });
        }

        function checkCardErrors() {
            if (isBankTransfer) {

            } else {


            }
        }

        function handleMarketingQuestionsForm() {
            if (!!document.querySelector('.marketing-form')) {
                let mforms = document.querySelectorAll('.marketing-form');

                mforms.forEach(mform => {
                    let selects = mform.querySelectorAll('select');

                    if (selects.length > 0) {
                        ([...selects]).forEach(select => {
                            select.onchange = (e) => {
                                if (!!formEl.querySelector(`input[name="${select.name}"]`)) {
                                    let target = formEl.querySelector(`input[name="${select.name}"]`);

                                    target.value = select.value;
                                } else {
                                    let target = document.createElement("input");

                                    target.setAttribute("type", "hidden");
                                    target.setAttribute("name", select.name);
                                    target.setAttribute("value", select.value);

                                    formEl.appendChild(target);
                                }

                            }
                        });
                    }
                });
            }
        }

        function setBankTransferListeners() {
            let bank_transfer_chbox = document.getElementById('bank_transfer_is_active');

            if (bank_transfer_chbox) {
                bank_transfer_chbox.onchange = () => {
                    if (bank_transfer_chbox.checked) {
                        isBankTransfer = true;
                        elem.classList.add('isBankTransfer');
                    } else {
                        isBankTransfer = false;
                        elem.classList.remove('isBankTransfer');
                    }
                };

                if (bank_transfer_chbox.checked) {
                    isBankTransfer = true;
                    elem.classList.add('isBankTransfer');
                }
            }
        }

        function removeForm() {
            elem.remove();
        }


        initCard();
        setFocusHandlers();
        setSendButtonHandler();
        setBankTransferListeners();
        setFormSubmitHandler();
        handleMarketingQuestionsForm();
    }

})();