(function () {

    if (!!document.querySelector('.single-company__mobile-benefit-toggler')) {
        let mob_benefit = document.querySelectorAll('.single-company__mobile-benefit-toggler');

        mob_benefit.forEach(element => {
            
            let wrapper = element.closest('.single-company');

            if( wrapper ){
                element.onclick = () => {
                    wrapper.classList.toggle('isBenefitsToggled');
                }
            }

        });
    }

})();