function uscorp_update_recaptcha( input, cb ) {
    if( data.grecapthca_key ){
        grecaptcha.execute(
            data.grecapthca_key,
            { action: 'homepage' }
        ).then( function( token ) {
            input.value = token;
            if( cb && typeof cb === 'function' ){
                cb();
            }

        } );
    }
    
}

function init_recaptcha_token_listener() {
    document.addEventListener('uscorpgrecaptchaexecuted', function (event) {
        var fields = document.querySelectorAll(
            "input[name='uscorp-g-recaptcha']"
        );

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            field.setAttribute('value', event.detail.token);
        }
    });
}

export { init_recaptcha_token_listener, uscorp_update_recaptcha }